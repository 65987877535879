import { AccordionProviderValue } from '@szhsin/react-accordion/types/utils/constants'
import { MutableRefObject, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import GlobalContext from './GlobalContext.tsx'

type Category = 'quality' | 'impact'
const ClassificationButton = (props: {
  cat: Category
  rating: number
  text: string
  classification: { impact?: number; quality?: number }
  handleClassification: (cat: Category, rating: number) => void
}) => (
  <button
    className={`button button--small combined-button ${props.classification[props.cat] === props.rating && 'selected'}`}
    onClick={() => {
      props.handleClassification(props.cat, props.rating)
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg">
      <circle r="12" cx="15" cy="15" />
    </svg>
    <span dangerouslySetInnerHTML={{ __html: props.text }} />
  </button>
)
export const ExtendedFeedback = (props: {
  providerValue: AccordionProviderValue
  questionRef: MutableRefObject<null>
  feedbackRef: MutableRefObject<null>
  hintRef: MutableRefObject<null>
  requestId?: string | null
  userId?: string | null
  inputValue: string
}) => {
  const { t } = useTranslation()
  const [feedbackValue, setFeedbackValue] = useState('')
  const [feedbackReceived, setFeedbackReceived] = useState('')
  const [emailValue, setEmailValue] = useState(sessionStorage.getItem('email') || '')
  const { host, org } = useContext(GlobalContext)

  const [classification, setClassification] = useState<{ impact?: number; quality?: number }>({
    impact: undefined,
    quality: undefined,
  })

  const handleClassification = async (cat: 'quality' | 'impact', rating: number) => {
    setClassification({ ...classification, [cat]: rating })
  }

  const sendClassification = async () => {
    handleFeedback()
    try {
      const body: Record<string, string | number | undefined> = {
        ...classification,
        rID: props.requestId || '',
      }
      await fetch(`${host}/${org}/classification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
    } catch (error) {
      console.log('Error sending feedback data:', error)
    }
  }

  const handleFeedback = async () => {
    try {
      if (!feedbackValue) {
        return
      }
      setFeedbackReceived('')

      const body: Record<string, string> = {
        query: props.inputValue || 'Keine im Inputfeld',
        email: emailValue,
        body: feedbackValue,
      }
      sessionStorage.setItem('email', emailValue)
      if (props.requestId) {
        body.rID = props.requestId
      }
      if (props.userId) {
        body.uID = props.userId
      }
      try {
        const response = await fetch(`${host}/${org}/feedback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        const data = await response.text()
        if (data === 'OK') {
          setFeedbackValue('')
          setFeedbackReceived('OK')
        } else {
          setFeedbackReceived('ERROR')
        }
      } catch (e) {
        setFeedbackReceived('ERROR')
      }
    } catch (error) {
      console.log('Error sending feedback data:', error)
    }
  }
  /**
   Qualität der Vorschläge

   •	Die Vorschläge sind zum grössten Teil hilfreich (Bewertung: 3)
   •	Die Vorschläge sind teilweise hilfreich (Bewertung: 2)
   •	Die Vorschläge sind nicht hilfreich (Bewertung: 1)
   •	Ich weiss es nicht (Bewertung: 0)

   Zeiteinsparung

   •	Keine Zeiteinsparung (Bewertung: -1)
   •	Bis zu 15 Minuten (Bewertung: 15)
   •	Bis zu 30 Minuten (Bewertung: 30)
   •	Bis zu 1 Stunde (Bewertung: 60)
   •	Über 1 Stunde (Bewertung: 120)
   •	Ich weiss es nicht (Bewertung: 0)
     */
  return (
    <div className="box ">
      <div className={'classification'}>
        <div>
          <h4>Qualität der Vorschläge</h4>
          <ClassificationButton
            handleClassification={handleClassification}
            text={'Die Vorschläge sind zum grössten Teil hilfreich'}
            cat={'quality'}
            classification={classification}
            rating={3}
          />
          <ClassificationButton
            handleClassification={handleClassification}
            text={'Die Vorschläge sind teilweise hilfreich'}
            cat={'quality'}
            classification={classification}
            rating={2}
          />
          <ClassificationButton
            handleClassification={handleClassification}
            text={'Die Vorschläge sind nicht hilfreich'}
            cat={'quality'}
            classification={classification}
            rating={1}
          />

          <ClassificationButton
            handleClassification={handleClassification}
            text={'Ich weiss es nicht'}
            cat={'quality'}
            classification={classification}
            rating={0}
          />
        </div>
        <div>
          <h4>Zeiteinsparung</h4>

          <ClassificationButton
            handleClassification={handleClassification}
            text={'Keine Zeiteinsparung'}
            cat={'impact'}
            classification={classification}
            rating={-1}
          />

          <ClassificationButton
            handleClassification={handleClassification}
            text={'Bis zu 15 Minuten'}
            cat={'impact'}
            classification={classification}
            rating={15}
          />
          <ClassificationButton
            handleClassification={handleClassification}
            text={'Bis zu 30 Minuten'}
            cat={'impact'}
            classification={classification}
            rating={30}
          />
          <ClassificationButton
            handleClassification={handleClassification}
            text={'Bis zu 1 Stunde'}
            classification={classification}
            cat={'impact'}
            rating={60}
          />
          <ClassificationButton
            handleClassification={handleClassification}
            text={`Über 1 Stunde`}
            cat={'impact'}
            classification={classification}
            rating={120}
          />
          <ClassificationButton
            handleClassification={handleClassification}
            text={'Ich weiss es nicht'}
            cat={'impact'}
            classification={classification}
            rating={0}
          />
        </div>
      </div>

      <div className={`box__drawer box__drawer--is-open`}>
        <div className="box__drawer__content">
          <label htmlFor="feedback">{t('contact.messageLabel')}</label>
          <textarea
            id="feedback"
            value={feedbackValue}
            onChange={event => setFeedbackValue(event.target.value)}
            style={{ height: 'auto', width: '100%', maxWidth: '700px' }}
            rows={5}
          ></textarea>
          <label htmlFor="email">{t('contact.emailLabel')}</label>
          <input
            id="email"
            value={emailValue}
            onChange={event => setEmailValue(event.target.value)}
            style={{ height: 'auto', width: '100%', maxWidth: '700px' }}
          />
        </div>
        <button
          disabled={classification.quality === undefined && classification.impact === undefined && !feedbackValue}
          className={`button button--small combined-button `}
          onClick={() => sendClassification()}
          style={{ marginTop: '1em' }}
        >
          <span>Abschicken</span>
        </button>
        {feedbackReceived === 'OK' && <span>{t('contact.feedbackReceived')}</span>}
        {feedbackReceived === 'ERROR' && <span>{t('contact.feedbackReceivedError')}</span>}
      </div>
    </div>
  )
}
